import * as React from "react";
import { useState, useEffect } from "react";
import "rsuite/dist/rsuite.css";
import { Helmet } from "react-helmet-async";
import TablePaginationActions from "../../common/components/pagination.actions";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { DateRangePickerCustom } from "../../common/components/daterangepicker.custom";
import ProgressLoader from "../../common/components/progress.loader";
import { endOfDay, startOfMonth } from "date-fns";
import { DealerLocationFilter } from "../DashboardApp/dashboard.dealerlocationfilter";
import { DashboardDataLoader } from "../DashboardApp/dashboard.dataloader";
import { TransactionTable } from "./transaction.table";
import {
  GetTransactionRequest,
  TransactionDataLoader,
} from "./transaction.dataloader";
import EnhancedTableHead from "../../common/components/enhancedtable/enhancedtablehead";
import { PaymentStatus } from "../../types/enums";
import { isEmpty } from "../../utils/Util";
import { GetDealerPlatformFeeOption } from "../../utils/UtilTarget";

const Transaction = () => {
  const today = new Date();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const showActionColumn = useAppSelector(
    (state: RootState) => state.transactionSettings.showActionColumn
  );
  const shouldShowActionColumn = (): string => {
    return showActionColumn ? "table-cell" : "none";
  };

  const [IsPlatformFeeEnabled, setIsPlatformFeeEnabled] =
    React.useState<any>(false);

  const ShowPlatformFeeColumn = (): string => {
    return IsPlatformFeeEnabled ? "table-cell" : "none";
  };

  // filters
  const [isSearchFieldEmpty, setIsSearchFieldEmpty] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState("");
  const defaultDateRange = [startOfMonth(today), endOfDay(today)];
  const [dealerLocationsID, setDealerLocationID] = useState<number>(-1);
  const [dealerLocations, setDealerLocations] = useState<any>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    defaultDateRange[0],
    defaultDateRange[1],
  ]);

  const [isFetching, setIsFetching] = React.useState(true);
  const [transactions, setTransactions] = React.useState<any>();
  const [sortField, setSortField] = React.useState<any>("dateCreated");
  const [sortOrder, setSortOrder] = React.useState<any>("desc");

  const dealerId = useAppSelector((state: RootState) => state.dealer.dealerId);
  const columns = [
    {
      label: "Order#",
      accessor: "invoiceId",
      sortable: true,
      display: "table-cell",
    },
    {
      label: "Reference",
      accessor: "paymentId",
      sortable: true,
      display: "table-cell",
    },
    {
      label: "Location",
      accessor: "dealerLocation",
      sortable: true,
      display: "table-cell",
    },
    {
      label: "Customer",
      accessor: "customerName",
      sortable: true,
      display: "table-cell",
    },
    {
      label: "Amount",
      accessor: "requestedAmount",
      sortable: true,
      align: "right",
      display: "table-cell",
    },
    {
      label: "Platform fee",
      accessor: "platformFee",
      sortable: true,
      align: "right",
      display: ShowPlatformFeeColumn(),
    },
    {
      label: "Total",
      accessor: "totalAmount",
      sortable: true,
      align: "right",
      display: ShowPlatformFeeColumn(),
    },
    {
      label: "Status",
      accessor: "CompositeStatus",
      sortable: true,
      display: "table-cell",
    },
    {
      label: "Transaction Date",
      accessor: "dateCreated",
      sortable: true,
      defaultSort: true,
      display: "table-cell",
    },
    {
      label: "Card",
      accessor: "paymentMethod",
      sortable: true,
      align: "center",
      display: "table-cell",
    },
    {
      label: "Action",
      accessor: "",
      sortable: false,
      align: "center",
      display: shouldShowActionColumn(),
    },
  ];

  useEffect(() => {
    DashboardDataLoader.GetDealerLocations(dealerId).then((response) => {
      setDealerLocations(response);
    });
  }, []);

  useEffect(() => {
    loadTransactionData();
  }, [
    page,
    rowsPerPage,
    selectedDateRange,
    dealerLocationsID,
    sortField,
    sortOrder,
  ]);

  useEffect(() => {
    if (isSearchFieldEmpty) {
      loadTransactionData();
    }
  }, [isSearchFieldEmpty]);

  useEffect(() => {
    setIsPlatformFeeEnabled(GetDealerPlatformFeeOption());
  }, []);

  function loadTransactionData() {
    setIsFetching(true);

    const request: GetTransactionRequest = {
      dealerLocationId: dealerLocationsID,
      page: page + 1,
      rowsPerPage: rowsPerPage,
      fromDate: selectedDateRange[0],
      toDate: selectedDateRange[1],
      searchCriteria: searchCriteria,
      sortField: sortField,
      sortOrder: sortOrder,
    };

    TransactionDataLoader.GetTransactions(request)
      .then((response) => {
        addCompositeStatus(response);
        setTransactions(response);
        setIsFetching(false);
      })
      .catch((e) => {
        alert(e);
      });
  }

  function addCompositeStatus(transactions) {
    if (transactions && transactions.data && transactions.data.length > 0) {
      transactions.data.forEach((transaction) => {
        if (transaction.amountProcessed === 0) {
          transaction["compositeStatus"] = PaymentStatus.SaveCard;
        } else if (transaction.isRefunded) {
          transaction["compositeStatus"] = PaymentStatus.Refunded;
        } else if (transaction.refundInitiated) {
          transaction["compositeStatus"] = PaymentStatus.PendingRefund;
        } else if (transaction.isCancelled) {
          transaction["compositeStatus"] = PaymentStatus.Cancelled;
        } else if (transaction.status) {
          transaction["compositeStatus"] = PaymentStatus[transaction.status];
        } else {
          transaction["compositeStatus"] = PaymentStatus.Pending;
        }
      });
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function GetCardNumber(row: any) {
    try {
      const myResult = JSON.parse(row.result);
      return myResult.maskedAccount;
    } catch (err) {
      return "";
    }
  }

  const handleDateRangePickerChange = (value: [Date, Date]) => {
    setSelectedDateRange(value);
  };

  const handleDealerLocationChange = (value) => {
    setDealerLocationID(value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.keyCode == 13) {
      // Enter Key
      loadTransactionData();
    }
  };

  const handleSearchChange = (e) => {
    setSearchCriteria(e.target.value);

    if (isEmpty(e.target.value)) {
      setIsSearchFieldEmpty(true);
    } else {
      setIsSearchFieldEmpty(false);
    }
  };

  const handleSorting = (sortField, sortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  return (
    <>
      <Helmet>
        <title> Transactions | Target Pay </title>
      </Helmet>
      <Box>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Transactions
            </Typography>
            <Stack direction="row" alignItems="center">
              <TextField
                id="outlined-search"
                placeholder="Search"
                sx={{ width: "300px", marginRight: "20px" }}
                size="small"
                value={searchCriteria}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <DealerLocationFilter
                dealerLocationsID={dealerLocationsID}
                dealerLocations={dealerLocations}
                onDealerLocationChange={handleDealerLocationChange}
              ></DealerLocationFilter>
              <DateRangePickerCustom
                dateRange={defaultDateRange}
                onDateRangePickerChange={handleDateRangePickerChange}
              ></DateRangePickerCustom>
            </Stack>
          </Stack>
        </Box>
        <Box component={Paper}>
          {isFetching ? (
            <>
              <TableContainer sx={{ height: "80vh" }}>
                <Table size="small" className="sticky-header sortable">
                  <EnhancedTableHead
                    columns={columns}
                    handleSorting={() => {}}
                    currentSortField=""
                    currentSortOrder=""
                  />
                </Table>
              </TableContainer>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "5",
                  top: "30%",
                  left: "50%",
                  height: "68vh",
                }}
              >
                <ProgressLoader isFetching={isFetching}></ProgressLoader>
              </Box>
            </>
          ) : (
            <Box>
              <TransactionTable
                filteredData={transactions?.data || []}
                columns={columns}
                dealerId={dealerId}
                handleSorting={handleSorting}
                currentSortField={sortField}
                currentSortOrder={sortOrder}
              ></TransactionTable>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                style={{ marginTop: "5px", borderTop: "1px solid #c8c8c8" }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        count={transactions?.rowCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Transaction;
