import { TableCell } from "@mui/material";
import { PaymentStatus } from "../../types/enums";

export default function PaymentStatusColumn({
  isRefunded,
  isCancelled,
  status,
  refundInitiated,
  handleClick,
  renderAsColumn = true,
}) {
  let statusText = isRefunded
    ? PaymentStatus.Refunded
    : refundInitiated
      ? PaymentStatus.PendingRefund
      : isCancelled
        ? PaymentStatus.Cancelled
        : status?.toLowerCase() == "failure" || status?.toLowerCase() == "failed"
          ? "Failed"
          : status?.toLowerCase() == "link sent"
            ? PaymentStatus.Pending
            : status
              ? PaymentStatus[status]
              : PaymentStatus.Pending;
  if (status == PaymentStatus.SaveCard) statusText = PaymentStatus.SaveCard;

  let statusColor =
    isRefunded || isCancelled || status == "Cancelled"
      ? "orange"
      : refundInitiated
        ? "black"
        : status?.toLowerCase() == "failure" || status?.toLowerCase() == "failed"
          ? "red"
          : status?.toLowerCase() == "success"
            ? "green"
            : status?.toLowerCase() == "save card"
              ? "green"
              : "black";
  if (status == PaymentStatus.SaveCard) statusColor = "green";

  return renderAsColumn ? (
    <TableCell onClick={handleClick} style={{ color: statusColor }}>
      {statusText}
    </TableCell>
  ) : (
    <label style={{ color: statusColor }}>{statusText}</label>
  );
}
