import {
  Box,
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import EnhancedTableHead from "../../common/components/enhancedtable/enhancedtablehead";
import PaymentMehtodType from "../../common/components/paymentmethod.type";
import { convertToUSD } from "../../utils/Util";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import TransactionAuditTrail from "./transaction.audittrail";
import React from "react";
import PaymentStatusColumn from "../../common/components/paymentstatus.column";
import { PaymentStatus } from "../../types/enums";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { GetDealerPlatformFeeOption } from "../../utils/UtilTarget";

export function TransactionTable({
  filteredData,
  columns,
  dealerId,
  handleSorting,
  currentSortField,
  currentSortOrder,
}) {
  const drawerRef = useRef<any>();
  //-- Turn on following for client side sorting
  //const [tableData, handleSorting] = useSortableTable(filteredData, columns);

  const [voidTransaction, setVoidTransaction] = React.useState(false);
  const [anchorVoid, setAnchorVoid] = React.useState(null);
  const [isVoiding, setIsVoiding] = React.useState(false);

  const [currentRow, setCurrentRow] = useState([] as any);
  const showActionColumn = useAppSelector(
    (state: RootState) => state.transactionSettings.showActionColumn
  );

  const [IsPlatformFeeEnabled, setIsPlatformFeeEnabled] =
    React.useState<any>(false);

  useEffect(() => {
    setIsPlatformFeeEnabled(GetDealerPlatformFeeOption());
  }, []);

  const shouldShowActionColumn = (): string => {
    return showActionColumn ? "table-cell" : "none";
  };

  function ShowVoid(row: any) {
    if (
      row.status == PaymentStatus.Success &&
      !row.isRefunded &&
      !row.isCancelled
    ) {
      return false;
    }
    // // const myResult = JSON.parse(row.result);
    // // if (myResult.transactionType == 'GenericVoid') {
    // // 	return true;
    // // }

    return true;
  }

  async function ConfirmVoidTransaction(e: any, row: any) {
    setVoidTransaction(true);
    setAnchorVoid(e.currentTarget);
    setCurrentRow(row);
  }

  // async function VoidTransaction(row: any) {
  async function VoidTransaction() {
    setIsVoiding(true);

    let obj: any = new Object();
    obj.dealerId = dealerId;
    obj.externalTransId = currentRow.externalTransId;
    obj.uniqueTransId = currentRow.uniqueTransId;
    let jsonString = JSON.stringify(obj);
    const authToken = localStorage.getItem("access_token");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    var url = `${baseUrl}Transaction/VoidTransaction`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: jsonString,
    };
    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        alert(error);
        console.error("There was an error!", error);
      });

    setVoidTransaction(false);
    setIsVoiding(false);
  }

  return (
    <TableContainer sx={{ height: "76vh" }} style={{overflow:"auto", scrollbarWidth: "thin" }}>
      <Table size="small" className="sticky-header sortable">
        <EnhancedTableHead
          columns={columns}
          handleSorting={handleSorting}
          currentSortField={currentSortField}
          currentSortOrder={currentSortOrder}
        />
        <TableBody>
          {!filteredData || filteredData.length == 0 ? (
            <TableRow>
              <TableCell colSpan={9}>No record found.</TableCell>
            </TableRow>
          ) : (
            filteredData.map((row: any, index) => (
              <TableRow hover key={index} style={{ cursor: "pointer" }}>
                <TableCell 
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  style={{
                    textDecorationLine: row.CancelOrRefund
                      ? "line-through"
                      : "",
                    whiteSpace: "nowrap"
                  }}
                >
                  {row.invoiceId}
                </TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  component="th"
                  scope="row"
                >
                  {row.paymentId}
                </TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  component="th"
                  scope="row"
                >
                  {row.dealerLocation}
                </TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  component="th"
                  scope="row"
                >
                  {row.customerName}
                </TableCell>
                <TableCell
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  align="right"
                >
                  {convertToUSD(row.transactionAmount)}
                </TableCell>
                {IsPlatformFeeEnabled && (
                  <>
                    <TableCell
                      onClick={(e) => {
                        drawerRef.current.openDrawer(row);
                      }}
                      align="right"
                    >
                      {convertToUSD(row.platformFee)}
                    </TableCell>
                    <TableCell
                      onClick={(e) => {
                        drawerRef.current.openDrawer(row);
                      }}
                      align="right"
                    >
                      {convertToUSD(row.totalAmount)}
                    </TableCell>
                  </>
                )}
                <PaymentStatusColumn
                  isRefunded={row.isRefunded}
                  isCancelled={row.isCancelled}
                  refundInitiated={row.refundInitiated}
                  status={row.totalAmount === 0 ? PaymentStatus.SaveCard : row.Status}
                  handleClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                ></PaymentStatusColumn>
                <TableCell style={{whiteSpace: "nowrap"}}
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  align="left"
                >
                  {moment
                    .utc(row.transactionDate)
                    .local()
                    .format("M/DD/YYYY hh:mm A")}
                </TableCell>
                <TableCell
                  onClick={(e) => {
                    drawerRef.current.openDrawer(row);
                  }}
                  align="center"
                >
                  <PaymentMehtodType
                    type={row.transactionType}
                  ></PaymentMehtodType>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ display: shouldShowActionColumn() }}
                >
                  <Button
                    disabled={ShowVoid(row)}
                    variant="outlined"
                    color="error"
                    style={{ textTransform: "none", padding: "00px" }}
                    onClick={(e) => ConfirmVoidTransaction(e, row)}
                  >
                    Refunded
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TransactionAuditTrail ref={drawerRef}></TransactionAuditTrail>
      <Popover
        open={voidTransaction}
        // open={true}
        anchorEl={anchorVoid}
        // onClose={handleCloseOrderDetail}
        onClose={(e) => setVoidTransaction(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          <div style={{ padding: "5px 10px" }}>
            <Box style={{ margin: "15px" }}>
              <Box>Are you sure you want to void this payment transaction?</Box>
              <Box style={{ marginTop: "20px", textAlign: "center" }}>
                <Button
                  disabled={isVoiding == true}
                  variant="contained"
                  onClick={VoidTransaction}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => setVoidTransaction(false)}
                  style={{ marginLeft: "15px" }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Popover>
    </TableContainer>
  );
}
